<template>
    <div class="mm-container inner-container">
        <div class="inner-left-col">
            <sidebar />
        </div>
        <div class="inner-right-col">
            <div class="block">
                <h2>I Build Websites & Web Applications</h2>
                <p>I am Murtaza based in Indore, India! I am currently focusing on building websites and web apps. I’ve been building stuff on the web for the last 11 years, working with clients across various countries and industries. I have a simple philosophy to finish what I start and not to stop before the finish line. </p>
                <p>I also run and manage <a href="https://www.webimpact.co.in" target="_blank">Web Impact</a>, an agency dedicated to solve problems using technology. I have an awesome with talented people and we have worked on 100s of projects and SAAS solutions.</p>
                <p>When I am not writing code, I enjoy reading and playing Playstation games.</p>    
            </div>
            <div class="block">
                <h3>Favourite Tech Stack</h3>
                <div class="tech-stacks">
                    <div class="tech-stack laravel">
                        <div class="stack-logo">
                            <img src="../img/laravel.svg" alt="Laravel">
                        </div>
                        <div class="stack-title">
                            <h4>Laravel</h4>
                        </div>
                    </div>
                    <div class="tech-stack wordpress">
                        <div class="stack-logo">
                            <img src="../img/wordpress.svg" alt="WordPress">
                        </div>
                        <div class="stack-title">
                            <h4>WordPress</h4>
                        </div>
                    </div>
                    <div class="tech-stack vuejs">
                        <div class="stack-logo">
                            <img src="../img/vue.svg" alt="Vue.js">
                        </div>
                        <div class="stack-title">
                            <h4>Vue.js</h4>
                        </div>
                    </div>
                    <div class="tech-stack codeigniter">
                        <div class="stack-logo">
                            <img src="../img/codeigniter.svg" alt="Codeigniter">
                        </div>
                        <div class="stack-title">
                            <h4>Codeigniter</h4>
                        </div>
                    </div>
                    <div class="tech-stack nodejs">
                        <div class="stack-logo">
                            <img src="../img/nodejs.svg" alt="Node.js">
                        </div>
                        <div class="stack-title">
                            <h4>Node.js</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <h3>Favourite Books</h3>
                <ul>
                    <li> > Rich Dad, Poor Dad — Robert Kiyosaki and Sharon Lechter</li>
                    <li> > Atomic Habits — James Clear</li>
                    <li> > The Happiness Hypothesis - Jonathan Haidt</li>
                </ul>
            </div>
            <div class="block">
                <h3>Favourite Games</h3>
                <ul>
                    <li> > God of War — Santa Monica Studios</li>
                    <li> > The Last of Us — Naughty Dogs</li>
                    <li> > The Uncharted — Naughty Dogs</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'AboutMe',
    components: {
        Sidebar
    }
}
</script>